<template>
  <div class="text-center">
    <help-medica-logo class="w-25" />
  </div>
</template>

<script>
import HelpMedicaLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    HelpMedicaLogo,
  },
}
</script>

<style>

</style>
